<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-13 17:08:14
 * @LastEditTime: 2022-11-22 18:56:59
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\CourseSort\nextRank.vue
-->
<template>
  <div class="nextRank">
    <div class="header">
      <el-button
        type="primary"
        size="medium"
        @click="dialogSelectVisible = true"
      >
        添加课程
      </el-button>
      <div class="right">
        <el-select
          v-model="post.course_type"
          style="width:110px"
          class="mr20"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="post.price_type"
          style="width:110px"
          class="mr20"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="post.status"
          style="width:110px"
          class="mr20"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          clearable
          class="mr10"
          style="width:218px"
          v-model="post.search"
          size="medium"
          @keyup.enter.native="toSearch"
          placeholder="输入名称搜索"
        ></el-input>
        <el-button type="primary" size="medium" @click="toSearch">
          搜索
        </el-button>
      </div>
    </div>
    <div class="main_contain">
      <pagination2
        :option="drawerPost"
        url="/courseSet/newGetCourseList"
        ref="courseSortListBox"
        :allFn="true"
        @complete="completeRecord"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column width="45px" type="selection"></el-table-column>
            <el-table-column label="序号" width="60px" v-if="!isSearching">
              <template slot-scope="{ row, $index }">
                <div style="width:50px">
                  <el-popover
                    placement="right"
                    :ref="`popover1${$index}`"
                    width="410"
                    trigger="click"
                  >
                    <div class="contain">
                      排列至第
                      <el-input
                        v-model="rankNumber"
                        class="ml30 mr10"
                        style="width:100px"
                        @keyup.enter.native="setSort(row)"
                        placeholder="输入数字"
                        size="medium"
                        onkeyup="value = value.replace(/[^\d]/g, '')"
                      ></el-input>
                      位
                      <el-button
                        size="medium"
                        type="primary"
                        class="ml10 mr10"
                        @click="setSort(row)"
                      >
                        确定
                      </el-button>
                      <el-button type="" size="medium" @click="cancel1($index)">
                        取消
                      </el-button>
                    </div>
                    <div class="cp" slot="reference">
                      {{ row.sort }}
                      <i class="el-icon-edit"></i>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="课程" min-width="170px">
              <template slot-scope="{ row }">
                <div class="course_contain" style="cursor: pointer;" @click="toManage(row)">
                  <div class="tu">
                    <img :src="row.photo" alt="" />
                  </div>
                  <div class="right">
                    <div class="name">{{ row.name }}</div>
                    <div class="price_crossp">
                      <div class="price">￥{{ row.price }}</div>
                      <div class="crossp" v-if="row.costprice">
                        ￥{{ row.costprice }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="课程类型">
              <template slot-scope="{ row }">
                {{ row.course_type | getcourseType }}
              </template>
            </el-table-column>
            <el-table-column label="展示到网校">
              <template slot-scope="{ row }">
                <span v-if="row.is_show == 1">已开启</span>
                <span v-else>已关闭</span>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1" style="color:#3D80EF">已上架</span>
                <span v-else style="color:#FF3535">已下架</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="170px">
              <template slot-scope="{ row }">
                <el-popover
                  placement="right"
                  :ref="`popover1${row.course_id}`"
                  width="410"
                  trigger="click"
                >
                  <div class="contain">
                    排列至第
                    <el-input
                      v-model="rankNumber"
                      class="ml30 mr10"
                      style="width:100px"
                      @keyup.enter.native="setSort(row)"
                      placeholder="输入数字"
                      size="medium"
                      onkeyup="value = value.replace(/[^\d]/g, '')"
                    ></el-input>
                    位
                    <el-button
                      size="medium"
                      type="primary"
                      class="ml10 mr10"
                      @click="setSort(row)"
                    >
                      确定
                    </el-button>
                    <el-button
                      type=""
                      size="medium"
                      @click="cancel1(row.course_id)"
                    >
                      取消
                    </el-button>
                  </div>
                  <el-button type="text" slot="reference">排序</el-button>
                </el-popover>

                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click="changeType(row)">
                  修改分类
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click="removeCourse(row, 1)">
                  移出分类
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click="getShareInfo(row)">
                  分享
                </el-button>
                <!-- 排序 | 修改分类 | 移出分类 -->
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer>
          <div class="footer">
            <span class="ml10">已选择0条</span>
            <el-button
              class="mr10 ml10"
              size="medium"
              :disabled="course_ids.length == 0"
              @click="removeCourse(course_ids, 2)"
            >
              批量移出分类
            </el-button>
            <el-button
              size="medium"
              :disabled="course_ids.length == 0"
              @click="changeType()"
            >
              批量修改分类
            </el-button>
          </div>
        </template>
      </pagination2>
    </div>

    <el-dialog
      title="选择课程(多选)"
      :close-on-click-modal="false"
      width="850px"
      :visible.sync="dialogSelectVisible"
      v-if="dialogSelectVisible"
    >
      <template>
        <div class="dialogContent">
          <div class="headerSelect">
            <div class="storeDialogBox">
              <el-select
                v-model="courseSortDialogPost.set_type"
                placeholder="全部分组"
              >
                <el-option
                  v-for="item in DialogBoxOptions_3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div class="storeDialogBox">
              <el-select
                v-model="courseSortDialogPost.course_type"
                placeholder="全部类型"
              >
                <el-option
                  v-for="item in DialogBoxOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div class="storeDialogBox">
              <el-select
                v-model="courseSortDialogPost.price_type"
                placeholder="全部售价"
              >
                <el-option
                  v-for="item in DialogBoxOptions_2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="inputDialogBox">
              <el-input
                placeholder="输入课程名称搜索"
                v-model="courseSortDialogPost.search"
                class="input-with-select"
              >
                <!-- 搜索 -->
              </el-input>
            </div>
          </div>

          <div class="pagiantionBox">
            <pagination2
              :option="courseSortDialogPost"
              url="/courseSet/selectCourse"
              ref="courseSortDialogBox"
              :allFn="true"
            >
              <template v-slot:default="{ tableData }">
                <el-table
                  :data="tableData"
                  ref="courseSortDialogTable"
                  :row-key="getRowkey"
                  @selection-change="DialogBoxSelectionChange"
                  @row-click="handleRowClick"
                  max-height:200
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                >
                  <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="55"
                  ></el-table-column>
                  <el-table-column prop="name" label="课程" min-width="220">
                    <template slot-scope="scope">
                      <div class="className_2">
                        <img :src="scope.row.photo" alt="" />
                        <p :title="scope.row.name">{{ scope.row.name }}</p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="file_size" label="类型">
                    <template slot-scope="scope">
                      <p>
                        {{
                          scope.row.course_type == 1
                            ? '直播课'
                            : scope.row.course_type == 2
                            ? '小班课'
                            : scope.row.course_type == 3
                            ? '录播课'
                            : '系列课'
                        }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="proportion" label="售价">
                    <template slot-scope="scope">
                      <div class="titleActivit">
                        <p style="color: #ff3535">
                          {{
                            scope.row.price == 0
                              ? '免费'
                              : '￥' + scope.row.price
                          }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </pagination2>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelSelectcourse">取 消</el-button>
          <el-button type="primary" @click="SubmitFix">
            确 定({{ listSave.length }})
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改分类 -->
    <el-dialog
      title="修改分类"
      :visible.sync="dialogVisible"
      width="422px"
      :close-on-click-modal="false"
    >
      <div class="dialog_contain">
        <div class="item">当前分类：{{ set_name }}</div>
        <div class="item">
          选择分类：
          <el-cascader
            @focus="newGetSetList"
            :props="{ checkStrictly: true }"
            size="medium"
            v-model="ascaderVal"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceldialog">取 消</el-button>
        <el-button type="primary" @click="submittype">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!--分享弹窗-->
    <sharePop
      v-if="shareId"
      @closeFn="toggleShareId"
      :shareInfo="shareInfo"
    ></sharePop>
  </div>
</template>

<script>
import sharePop from '@/components/coursePop/sharePop'
import API from '@/request/api'
export default {
  name: 'nextRank',

  data() {
    return {
      isSearching: false,

      listSave: [],

      DialogBoxOptions: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: '1',
          label: '直播课',
        },
        {
          value: '2',
          label: '小班课',
        },
        {
          value: '3',
          label: '录播课',
        },
        {
          value: '4',
          label: '系列课',
        },
      ],

      DialogBoxOptions_2: [
        {
          value: '0',
          label: '全部售价',
        },
        {
          value: '1',
          label: '付费',
        },
        {
          value: '2',
          label: '免费',
        },
      ],

      DialogBoxOptions_3: [
        {
          value: '0',
          label: '全部分组',
        },
        {
          value: '1',
          label: '已分组',
        },
        {
          value: '2',
          label: '未分组',
        },
      ],

      courseSortDialogPost: {
        //dialog的分页post
        set_id: this.set_id,
        search: '',
        set_type: '0',
        course_type: '0',
        price_type: '0',
      },

      dialogSelectVisible: false, //添加课程控制开关

      addCourse: false,

      selectval: '',

      ascaderVal: [],

      options: [],

      dialogVisible: false,

      course_ids: [],

      rankNumber: '',

      shareId: '',

      shareInfo: {},

      post: {
        course_type: 0,
        price_type: 0,
        status: 0,
        search: '',
      },

      drawerPost: {
        set_id: '',
      },

      options1: [
        {
          value: 0,
          label: '课程类型',
        },
        {
          value: 1,
          label: '直播课',
        },
        {
          value: 3,
          label: '录播课',
        },
        {
          value: 2,
          label: '小班课',
        },
        {
          value: 4,
          label: '系列课',
        },
      ],
      options2: [
        {
          value: 0,
          label: '付费类型',
        },
        {
          value: 2,
          label: '免费',
        },
        {
          value: 1,
          label: '付费',
        },
        {
          value: 3,
          label: '密码',
        },
      ],
      options3: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '已上架',
        },
        {
          value: 2,
          label: '已下架',
        },
      ],
    }
  },

  components: {
    sharePop,
  },

  mounted() {
    this.courseSortDialogPost.set_id = this.set_id
    this.$root.$children[0].childPageOptions[1].name = this.set_name
    this.drawerPost.set_id = this.set_id
    this.newGetSetList()
  },

  computed: {
    type() {
      let val = ''
      if (this.$route.name == 'nextRank') {
        val = 1
      } else {
        val = 2
      }
      return val
    },

    set_id() {
      return this.$route.query.set_id
    },

    set_name() {
      return this.$route.query.set_name
    },
  },

  filters: {
    getcourseType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
      }
      return text
    },
  },

  methods: {
    handleRowClick(row) {
      //点击每一行勾选课程
      this.$refs.courseSortDialogTable.toggleRowSelection(row)
    },

    getRowkey(row) {
      // console.log(row)
      //分页时记住勾选状态
      return row.course_id
    },

    DialogBoxSelectionChange(val) {
      //点击选择暂存选项数组
      this.listSave = val
      // console.log(this.listSave)
    },

    cancelSelectcourse() {
      this.dialogSelectVisible = false
      this.listSave = []
    },

    SubmitFix() {
      //点击弹出框的确定
      var course_idsArr = []
      for (let value of this.listSave.values()) {
        // console.log(value.course_id)
        //遍历暂存的数组,赋值到即将上传的字段
        course_idsArr.push(value.course_id)
      }
      // course_idsArr = course_idsArr.join(",")
      // console.log(course_idsArr)
      this.$http({
        url: '/courseSet/courseToSet',
        data: {
          course_ids: course_idsArr,
          set_id: this.drawerPost.set_id,
        },
        callback: () => {
          this.$root.prompt({
            msg: '添加成功',
            type: 'success',
          })
          this.$refs.courseSortListBox.reset()
          this.$refs.courseSortDialogBox.reset()
          this.cancelSelectcourse()
        },
      })
    },

    // 修改分类
    async submittype() {
      await this.$http({
        url: '/courseSet/setCourseSet',
        data: {
          set_id: this.ascaderVal[this.ascaderVal.length - 1],
          old_set_id: this.set_id,
          course_id:
            this.course_ids.length > 0
              ? this.course_ids
              : [this.selectval.course_id],
        },
      })
      this.$refs.courseSortListBox.reset()
      this.canceldialog()
    },

    // 取消分类
    canceldialog() {
      this.ascaderVal = []
      this.dialogVisible = false
    },

    changeType(item) {
      this.selectval = item && item.course_id ? item : ''
      this.dialogVisible = true
    },

    handleChange(val) {
      console.log(val)
    },

    // 获取分类列表
    async newGetSetList() {
      const { data } = await this.$http({
        url: '/courseSet/newGetSetList',
      })

      this.options = this.getdata(data)
    },

    getdata(val) {
      val.forEach(item => {
        item.value = item.set_id
        item.label = item.set_name
        if (item.children && item.children.length > 0) {
          this.getdata(item.children)
        }
      })
      return val
    },

    // 选择的课程
    handleSelectionChange(val) {
      this.course_ids = val.map(item => item.course_id)
    },

    // 移除分类
    async removeCourse(val, type) {
      this.$confirm('是否确定移出所选分类！', '移除分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/courseSet/removeCourse',
            data: {
              set_id: this.set_id,
              course_id: type == 1 ? [val.course_id] : val,
            },
          })
          this.$refs.courseSortListBox.reset()
        })
        .catch(() => {})
    },

    // 排序
    async setSort(item) {
      if (!this.rankNumber) {
        this.$root.prompt('请输入序号！')
        return
      }
      await this.$http({
        url: '/courseSet/courseSetSort',
        data: {
          set_id: this.set_id,
          course_id: item.course_id,
          sort: this.rankNumber,
        },
        successMsg: true,
      })
      this.$refs.courseSortListBox.reset()
      this.cancel1(item.course_id)
    },

    // 取消排序
    cancel1() {
      document.body.click()
      this.rankNumber = ''
    },

    // 搜索
    toSearch() {
      //  course_type: 0,
      //   price_type: 0,
      //   status: 0,

      if (
        this.post.search ||
        this.post.course_type != 0 ||
        this.post.price_type != 0 ||
        this.post.status != 0
      ) {
        this.isSearching = true
      } else {
        this.isSearching = false
      }

      this.drawerPost = _.assign({}, this.drawerPost, this.post)
    },

    completeRecord(val) {
      console.log(val)
    },
    // 进入管理页面 路由带参数
    toManage(info) {
      let path = ''
      switch (Number(info.course_type)) {
        // 直播课
        case 1:
          path='courseLive'
          break;
          // 小班课
        case 2:
          path='coursePrivate'
          break;
          // 录播课
        case 3:
          path='courseRecord'
          break;
      }
      this.$router.push({
        path: `/${path}/EditCourse`,
        query: {
          course_id: info.course_id,
          course_type: info.course_type,
          index: '2',
        },
      })
    },
    // 获取分享课程数据
    getShareInfo({ course_id }) {
      const self = this
      self.$http({
        name: API.coursePosterShare_Api.name,
        url: API.coursePosterShare_Api.url,
        data: {
          course_id,
        },
        callback({ code, data }) {
          if (code == 200) {
            self.shareId = course_id
            self.shareInfo = data
          }
        },
      })
    },
    // 显示分享弹窗页面
    toggleShareId() {
      this.shareId = ''
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  //   padding-top: 0 !important;
}
.nextRank {
  background-color: #fff;
  padding: 20px;
  .headerSelect {
    display: flex;
    justify-content: flex-end;

    .storeDialogBox {
      width: 160px;
      margin-right: 10px;
    }

    .el-input {
      width: 190px;
    }
  }
  .pagiantionBox {
    margin-top: 20px;
    overflow: auto;
    height: 338px;
    @extend %scroll;

    .className_2 {
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      img {
        width: 40px;
        height: 22px;
      }

      p {
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  ::v-deep .el-divider {
    background-color: #1b9d97 !important;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main_contain {
    margin-top: 77px;
    position: relative;
    .course_contain {
      display: flex;
      .tu {
        width: 66px;
        height: 38px;
        background: #d8d8d8;
        margin-right: 14px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #333333;
        }
        .price_crossp {
          display: flex;
          align-items: center;
          .price {
            font-size: 14px;
            color: #ff3535;
            margin-right: 15px;
          }
          .crossp {
            text-decoration: line-through;
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
    .footer {
      position: absolute;
      top: -50px;
      left: 0;
      span {
        font-size: 14px;
        color: #333333;
        line-height: 19px;
      }
    }
  }
  .dialog_contain {
    .item {
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
    }
  }
}
</style>
